/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect } from "react"
import styled, { useTheme } from "styled-components"
import dynamic from "next/dynamic"
import NextImg from "@/common/components/NextImg"
import { useMediaQuery } from "@mui/material"
import { theme } from "@/common/Mixin"
import { ProductNames, SolutionNames } from "@/common/data/Products"

export type BannerType = ProductNames | SolutionNames | "home"
const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

const SolutionBackgroundImage = (solutionType: SolutionNames) => {
  const theme = useTheme()
  const isPhone = useMediaQuery(`(${theme.sm})`)
  const [item, setItem] = useState<string>("")

  let fileName: string = ""
  switch (solutionType) {
    case SolutionNames.Defi:
      fileName = "defi/DeFiBanner"
      break
    case SolutionNames.GameFi:
      fileName = "gamefi/GameFiBanner"
      break
    case SolutionNames.Web3FundManagement:
      fileName = "web3-fund-management/banner"
      break
  }

  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect
  useIsomorphicLayoutEffect(() => {
    if (isPhone) {
      setItem(require(`@/common/assets/image/solutions/${fileName}iPhone.avif`))
    } else {
      setItem(require(`@/common/assets/image/solutions/${fileName}.avif`))
    }
  }, [isPhone])

  return (
    <div style={{ position: "relative", height: "120%", width: "100%" }}>
      <ShadowWrapper />
      <NextImg
        style={{ height: "100%" }}
        src={item}
        alt={`${solutionType} Banner`}
      />
    </div>
  )
}

export const ShadowWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 333;
  background: linear-gradient(0deg, rgb(0, 12, 34) 0%, rgba(0, 12, 34, 0) 20%),
    linear-gradient(
      60deg,
      rgb(0, 12, 34) 20%,
      rgba(0, 12, 34, 20%) 50%,
      rgba(0, 12, 34, 0) 100%
    );

  top: auto;
  bottom: -1px;

  @media (${theme.lg}) {
    background: linear-gradient(
      to top,
      ${theme.color.dark},
      ${theme.color.dark}00
    );
  }
  @media (${theme.xl}) {
    height: 100%;
  }
  overflow: hidden;
`

export const getBackground = (
  bannerType: BannerType | undefined
): React.ReactNode => {
  switch (bannerType) {
    case SolutionNames.Defi:
      return SolutionBackgroundImage(bannerType)
    case SolutionNames.GameFi:
      return SolutionBackgroundImage(bannerType)
    case SolutionNames.Web3FundManagement:
      return SolutionBackgroundImage(bannerType)
    default:
      return null
  }
}

const loadAnimationData = async (path: string) => {
  const mod = await import(`@/common/assets/image/${path}`)
  return mod.default
}

export const BannerImg = ({
  bannerType
}: {
  bannerType: BannerType | undefined
}) => {
  const [animationData, setAnimationData] = useState(null)

  useEffect(() => {
    const loadData = async () => {
      let path = ""
      switch (bannerType) {
        case ProductNames.WalletBuilder:
          path = "products/wallet/walletBuilder/Banner.json"
          break
        case ProductNames.WalletSDK:
          path = "products/wallet/sdk/Banner.json"
          break
        case ProductNames.WalletApplication:
          path = "products/wallet/application/Banner.json"
          break
        case ProductNames.WalletExtension:
          path = "products/wallet/extension/Banner.json"
          break
        case ProductNames.AssetPro:
          path = "products/wallet/assetPro/Banner.json"
          break
        case ProductNames.ComplyFlow:
          path = "products/compliance/complyFlow/Banner.json"
          break
        case ProductNames.ComplianceLite:
          path = "products/compliance/lite/Banner.json"
          break
        case ProductNames.CompliancePro:
          path = "products/compliance/pro/Banner.json"
          break
        case ProductNames.OAuthSDK:
          path = "products/onboarding/Banner.json"
          break
        case ProductNames.NFTBoost:
          path = "products/nft/boost/Banner.json"
          break
        case ProductNames.User360:
          path = "products/user360/Banner.json"
          break
        case ProductNames.NFTAirdrop:
          path = "products/nft/airdrop/Banner.json"
          break
        default:
          break
      }
      if (path) {
        const data = await loadAnimationData(path)
        setAnimationData(data)
      }
    }

    loadData()
  }, [bannerType])

  if (!bannerType) return null

  if (
    bannerType === ProductNames.WalletAPI ||
    bannerType === ProductNames.ComplianceAPI
  ) {
    const imgPath =
      bannerType === ProductNames.WalletAPI
        ? require("@/common/assets/image/products/wallet/api/Banner.webp")
            .default
        : require("@/common/assets/image/products/compliance/api/Banner.webp")
            .default
    return <NextImg src={imgPath} alt={bannerType} />
  }

  return animationData ? <Lottie animationData={animationData} /> : null
}
